@import "src/styles/_base";

.badge {
  display: inline-block;
  background-color: $base-color;
  color: $background-color;
  padding: 0.3rem 0.5rem;
  font-weight: $font-weight--medium;
  font-size: 0.8rem;
}
