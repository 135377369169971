@import "src/styles/_base";

.base {
  margin: 0;
  padding: 0;
  list-style: none;

  .point {
    padding: 0;
    margin: 0 0 $grid-gutter--large;
  }
}
