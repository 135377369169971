@import "src/styles/_base";

.heading {
  position: relative;
  color: #fff;
  padding: 4rem 4rem 6rem;
  max-width: 800px;
  margin: 0 auto;
  transition: height 0.2s linear;

  .title {
    h1 {
      font-weight: $font-weight--light;
      text-transform: uppercase;
      font-size: 3rem;
      margin: 0;
    }
  }

  .arrow {
    display: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 2rem;
    bottom: 4rem;
    animation: jumpInfinite 1.5s infinite;
    opacity: 1;
    transition: opacity 0.2s linear;

    svg {
      fill: $base-color;
    }
  }

  &.continue {
    .arrow {
      opacity: 0;
      bottom: 4rem;
    }
  }

  @keyframes jumpInfinite {
    0% {
      bottom: 4rem;
    }
    50% {
      bottom: 5rem;
    }
    100% {
      bottom: 4rem;
    }
  }

  @include for-mobile-down() {
    height: 100vh;
    padding: 4rem 2rem 4rem;

    .arrow {
      display: block;
    }
  }
}
