@import "src/styles/_base";

.svg_logo {
  display: block;
  aspect-ratio: 1 / 1;
  padding: 2rem 1.5rem;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;

  svg {
    width: 100%;
    height: 100%;
    fill: $base-color !important;

    path {
      fill: $base-color !important;
    }
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }

  @include for-mobile-down() {
    padding: 1.5rem 1rem;
  }
}
