/* FONT FAMILY
------------------------------------------------- */

$font-family--primary: "Raleway", sans-serif;
$font-family--titles: "Raleway", sans-serif;

/* FONT SIZE
------------------------------------------------- */

$font-size--regular: 16px;

/* FONT WEIGHT
------------------------------------------------- */

$font-weight--light: 200;
$font-weight--regular: 400;
$font-weight--medium: 700;
$font-weight--black: 900;

/* GRID
------------------------------------------------- */

$grid-columns: 24;
$grid-gutter--tiny: 0.25rem;
$grid-gutter--small: 0.5rem;
$grid-gutter--regular: 1rem;
$grid-gutter--large: 3rem;

/* COLORS
------------------------------------------------- */

$background-color: #323a4c;
$base-color: #ffffff;
