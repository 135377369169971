@import "src/styles/_base";

.base {
  opacity: 0;
  transition: opacity 0.5s linear;

  .label {
    padding: 0 0 0.3rem 0;
  }

  .trail {
    position: relative;
    height: 0.5rem;
    background: rgba(0, 0, 0, 0.15);

    .bar {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      background: $base-color;
      transition: width 2s ease-in-out;
    }
  }

  &.visible {
    opacity: 1;
  }
}
