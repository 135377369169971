@import "src/styles/_base";

.text {
  &.weightBold {
    font-weight: $font-weight--medium;
  }

  &.link {
    cursor: pointer;
    text-decoration: underline;
  }
}
