@import "src/styles/_base";

.section {
  margin-bottom: 10rem;

  .title {
    position: sticky;
    top: 0;
    z-index: 2;

    h2 {
      position: relative;
      font-size: 1.3rem;
      text-transform: uppercase;
      margin: 0;
      padding: 0.5rem;
      font-weight: $font-weight--black;
      z-index: 2;
      margin-left: -0.5rem;
    }

    &::after {
      position: absolute;
      content: " ";
      top: 0;
      left: -1rem;
      right: -1rem;
      height: 140%;
      background: linear-gradient(
        180deg,
        rgba($background-color, 1) 30%,
        rgba($background-color, 0) 100%
      );
      z-index: 1;
    }
  }

  .subtitle {
    color: rgba($base-color, 0.2) !important;
  }

  .content {
    margin-top: 2.5rem;
    padding-bottom: 1rem;
  }
}
