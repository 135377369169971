@import "src/styles/_base";

.content {
  max-width: 800px;
  margin: 0 auto;
  flex-grow: 1;
  padding: 4rem;

  @include for-mobile-down() {
    padding: 4rem 2rem 30vh 2rem;
  }
}
