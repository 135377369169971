@import "src/styles/_base";

* {
  box-sizing: border-box;
}

html {
  font-size: $font-size--regular;
}

body {
  margin: 0;
  font-family: $font-family--primary;
  font-weight: $font-weight--regular;
  background-color: $background-color;
  color: $base-color;
  line-height: 1.4;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: $base-color;
}
