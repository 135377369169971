@import "src/styles/_base";

/* ===================================================================
   GRID ROW
   =================================================================== */

.row {
  display: flex;
  flex-wrap: wrap;

  // GUTTER
  &.gutter_ver_tiny {
    margin-left: calc(#{$grid-gutter--tiny} / (-2));
    margin-right: calc(#{$grid-gutter--tiny} / (-2));
  }

  &.gutter_ver_small {
    margin-left: calc(#{$grid-gutter--small} / (-2));
    margin-right: calc(#{$grid-gutter--small} / (-2));
  }

  &.gutter_hor_tiny {
    margin-top: calc(#{$grid-gutter--tiny} * (-1));
  }

  &.gutter_hor_small {
    margin-top: calc(#{$grid-gutter--small} * (-1));
  }

  &.gutter_ver_regular {
    margin-left: calc(#{$grid-gutter--regular} / (-2));
    margin-right: calc(#{$grid-gutter--regular} / (-2));
  }

  &.gutter_hor_regular {
    margin-top: calc(#{$grid-gutter--regular} * (-1));
  }

  &.gutter_ver_large {
    margin-left: calc(#{$grid-gutter--large} / (-2));
    margin-right: calc(#{$grid-gutter--large} / (-2));
  }

  &.gutter_hor_large {
    margin-top: calc(#{$grid-gutter--large} * (-1));
  }
}
