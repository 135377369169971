$breakpoint--mobile: 600px;
$breakpoint--tablet: 900px;
$breakpoint--desktop: 1400px;
$breakpoint--widescreen: 1800px;

// BREAKPOINTS
@mixin for-mobile-down {
  @media (max-width: $breakpoint--mobile - 1px) {
    @content;
  }
}
@mixin for-mobile-up {
  @media (min-width: $breakpoint--mobile) {
    @content;
  }
}
@mixin for-tablet-only {
  @media (min-width: $breakpoint--mobile) and (max-width: $breakpoint--tablet - 1px) {
    @content;
  }
}
@mixin for-tablet-up {
  @media (min-width: $breakpoint--tablet) {
    @content;
  }
}
@mixin for-tablet-down {
  @media (max-width: $breakpoint--tablet - 1px) {
    @content;
  }
}
@mixin for-desktop-only {
  @media (min-width: $breakpoint--tablet) and (max-width: $breakpoint--desktop - 1px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: $breakpoint--desktop) {
    @content;
  }
}
@mixin for-desktop-down {
  @media (max-width: $breakpoint--desktop) {
    @content;
  }
}
@mixin for-widescreen-only {
  @media (min-width: $breakpoint--desktop) and (max-width: $breakpoint--widescreen - 1px) {
    @content;
  }
}
@mixin for-widescreen-up {
  @media (min-width: $breakpoint--widescreen) {
    @content;
  }
}
@mixin for-widescreen-down {
  @media (max-width: $breakpoint--widescreen) {
    @content;
  }
}
