@import "src/styles/_base";

/* ===================================================================
   GRID COL
   =================================================================== */

// MAKE GRID COLUMNS
// ----------------------------------------------------------

@mixin colList($index, $list) {
  @if ($index <= $grid-columns) {
    $item: ".col_#{$index}, .col_xs_#{$index}, .col_sm_#{$index}, .col_md_#{$index}, .col_lg_#{$index}";
    @include colList(($index + 1), "#{$list}, #{$item}");
  } @else if ($index > $grid-columns) {
    #{$list} {
      position: relative;
      min-height: 1px;
      width: 100%;
    }
  }
}

@mixin col($index) {
  $item: ".col_#{$index}, .col_xs_#{$index}, .col_sm_#{$index}, .col_md_#{$index}, .col_lg_#{$index}";
  @include colList(($index + 1), $item);
}

@mixin make-grid-columns() {
  @include col(1);
}

// MAKE LOOP GRID COLUMNS
// ----------------------------------------------------------

@mixin loop-grid-columns($index, $class) {
  @if ($index > 0) {
    .col#{$class}_#{$index} {
      width: percentage(calc($index / $grid-columns));
    }

    .col#{$class}_push_#{$index} {
      left: percentage(calc($index / $grid-columns));
    }

    .col#{$class}_pull_#{$index} {
      right: percentage(calc($index / $grid-columns));
    }

    .col#{$class}_offset_#{$index} {
      margin-left: percentage(calc($index / $grid-columns));
    }

    .col#{$class}_order_#{$index} {
      order: $index;
    }

    .col#{$class}_sticky {
      position: sticky;
      top: 0;
      // z-index: $zIndex--colSticky;
    }

    @include loop-grid-columns(($index - 1), $class);
  } @else if ($index == 0) {
    .col#{$class}_#{$index} {
      display: none;
    }

    .col_push_#{$index} {
      left: auto;
    }

    .col_pull_#{$index} {
      right: auto;
    }

    .col#{$class}_push_#{$index} {
      left: auto;
    }

    .col#{$class}_pull_#{$index} {
      right: auto;
    }

    .col#{$class}_offset_#{$index} {
      margin-left: 0;
    }

    .col#{$class}_order_#{$index} {
      order: 0;
    }
  }
}

@mixin make-grid($class: "") {
  //@include float-grid-columns($class);
  @include loop-grid-columns($grid-columns, $class);
}

// GRID GUTTER
// ----------------------------------------------------------

.gutter_ver_tiny {
  padding-left: calc(#{$grid-gutter--tiny} / 2);
  padding-right: calc(#{$grid-gutter--tiny} / 2);
}

.gutter_hor_tiny {
  margin-top: $grid-gutter--tiny;
}

.gutter_ver_small {
  padding-left: calc(#{$grid-gutter--small} / 2);
  padding-right: calc(#{$grid-gutter--small} / 2);
}

.gutter_hor_small {
  margin-top: $grid-gutter--small;
}

.gutter_ver_regular {
  padding-left: calc(#{$grid-gutter--regular} / 2);
  padding-right: calc(#{$grid-gutter--regular} / 2);
}

.gutter_hor_regular {
  margin-top: $grid-gutter--regular;
}

.gutter_ver_large {
  padding-left: calc(#{$grid-gutter--large} / 2);
  padding-right: calc(#{$grid-gutter--large} / 2);
}

.gutter_hor_large {
  margin-top: $grid-gutter--large;
}

.grow {
  flex-grow: 1;
}

.noShrink {
  flex-shrink: 0;
}

.compact {
  line-height: 0;
}

.align_hor_center {
  display: flex;
  justify-content: center;
}

.align_hor_right {
  display: flex;
  justify-content: flex-end;
}

.align_hor_left {
  display: flex;
  justify-content: flex-start;
}

.align_ver_center {
  display: flex;
  align-items: center;
}

.align_ver_bottom {
  display: flex;
  align-items: flex-end;
}

.align_ver_top {
  display: flex;
  align-items: flex-start;
}

@include make-grid-columns();
@include make-grid();

// Extra small grid
//
// Columns, offsets, pushes, and pulls for extra small devices like
// smartphones.

@include make-grid("_xs");

// Small grid
//
// Columns, offsets, pushes, and pulls for the small device range, from phones
// to tablets.

@include for-mobile-up() {
  @include make-grid("_sm");
}

// Medium grid
//
// Columns, offsets, pushes, and pulls for the desktop device range.

@include for-tablet-up() {
  @include make-grid("_md");
}

// Large grid
//
// Columns, offsets, pushes, and pulls for the large desktop device range.

@include for-desktop-up() {
  @include make-grid("_lg");
}
