@import "src/styles/_base";

.image {
  display: block;
  max-width: 100%;
  max-height: 100%;

  &.circle {
    border-radius: 50%;
  }

  &.grey {
    mix-blend-mode: luminosity;
    border: 3px solid rgba(0, 0, 0, 0.15);
    padding: 1px;
    position: relative;
    left: -3px;
  }
}
