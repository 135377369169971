@import "src/styles/_base";

$size: 10px;
$color1: $base-color;
$color2: rgba(0, 0, 0, 0.2);

.loader {
  display: block;
  margin-left: calc($size * 2);
  width: $size;
  height: $size;
  border-radius: 50%;
  background-color: $color1;
  box-shadow: $size * 2 0 $color1, calc($size * -2) 0 $color1;
  position: relative;
  animation: flash 1s ease-out infinite alternate;
}

@keyframes flash {
  0% {
    background-color: $color2;
    box-shadow: calc($size * 2) 0 $color2, calc($size * -2) 0 $color1;
  }
  50% {
    background-color: $color1;
    box-shadow: calc($size * 2) 0 $color2, calc($size * -2) 0 $color2;
  }
  100% {
    background-color: $color2;
    box-shadow: calc($size * 2) 0 $color1, calc($size * -2) 0 $color2;
  }
}
